import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { Bar, BarChart, Cell, LabelList, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { descending, sort } from 'd3-array'
import { AppContext } from '../App'
import PrecountLineChart from './PrecountLineChart'
import { addSuffix, toSlug } from '../utils'
import complementos from '../data/_complementos.json'
import DetailsPanelHeader from './DetailsPanelHeader'
import ComparisonChart from './ComparisonChart'

function CustomBarChartTick ({ value, offset, viewBox }) {
  return <text x={viewBox.width / 2 + viewBox.x} y={viewBox.y + viewBox.height / 2 + 10} offset={offset} width={viewBox.width} height={viewBox.height} fill="#ffffff" className="font-bold text-lg sm:text-2xl lg:text-4xl mb-8" textAnchor="middle">
    {value.toFixed(2)}%
  </text>
}

function DetailsPanel ({ current, data }) {
  const colors = ['#b47cf9', '#fa8030']
  const [info, setInfo] = useState({})
  const [national, setNational] = useState([])
  const [reports, setReports] = useState([])
  const [plotData, setPlotData] = useState([])
  const [selectedChart, setSelectedChart] = useState('preconteo')
  const { state } = useContext(AppContext)

  useEffect(() => {
    if (!Object.keys(data).length) return
    setInfo(data.info[0])
    setNational(sort(data.resultados_total, d => d.Candidato))
    setReports(data.resultados_boletines)
    setSelectedChart('preconteo')
  }, [data])

  useEffect(() => {
    if (selectedChart === 'preconteo') return
    const filtered = complementos.filter(entry => entry.eleccion === selectedChart)
    let records
    if (state.currentRegion === 'colombia') {
      records = filtered.find(item => item.nivel === 'colombia').data
    } else if (state.selectedMunicipality) {
      // console.log('Municipio')
      // console.log(filtered.find(item => item.nivel === 'municipios').data)
      // console.log('......')
      records = filtered.find(item => item.nivel === 'municipios').data
        .filter(item => item.id === toSlug(state.selectedMunicipality))
    } else {
      // console.log('Departamento')
      // console.log(filtered.find(item => item.nivel === 'departamentos').data)
      // console.log('......')
      records = filtered.find(item => item.nivel === 'departamentos').data
        .filter(item => item.id === state.currentRegion)
    }
    // console.log(records)
    // console.log('~~~~~~~~')
    setPlotData(sort(records, (a, b) => descending(a.porcentaje, b.porcentaje)))
  }, [selectedChart, state.currentRegion, state.selectedMunicipality])

  const handleChange = (e) => setSelectedChart(e.target.value)

  if (!Object.keys(data).length) return null

  return (<div className="text-space-cadet">
    <DetailsPanelHeader current={current} info={info} />
    <div className='mt-4'>
      <ResponsiveContainer height={250} className="text-xs mt-0">
        <BarChart data={national} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
          <ReferenceLine y={50} stroke="#324376" strokeDasharray="3 3" />
          <XAxis
            dataKey="Candidato"
            tickMargin={5}
            tickLine={false}
            axisLine={false}
            tick={{ fill: '#324376', fontSize: '0.9rem' }}
            tickFormatter={(value, name, props) => (
              value.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
            )}
          />
          <YAxis domain={[0, 70]} ticks={[50]} tickLine={false} tickCount={3} axisLine={false} style={{ fontSize: '12px' }} tickFormatter={(value) => addSuffix('%', value)} />
          <Tooltip
            separator={''}
            cursor={false}
            itemStyle={{ color: '#324376' }}
            wrapperStyle={{ color: '#324376' }}
            labelStyle={{ fontWeight: '300', textTransform: 'capitalize', fontSize: '12px', marginBottom: '2px' }}
            contentStyle={{ color: '#324376', fontWeight: 'bold', fontSize: '22px', borderColor: '#63A9DE', borderRadius: '15px', backgroundColor: 'rgba(255,255,255,0.95)', padding: '1.5rem' }}
            offset={5}
            formatter={(value, name, p) => [p.payload.Votos.toLocaleString('en-US') + ' votos', '']}
            labelFormatter={(value, name, props) => (
              value.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
            )}
          />
          <Bar dataKey="Porcentaje">
            {national.map((entry, index) => (
              <Cell key={index} fill={entry.Candidato === 'GUSTAVO PETRO' ? colors[0] : colors[1]} />
            ))}
            <LabelList dataKey="Porcentaje" position="top" content={<CustomBarChartTick />} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <hr className="my-5 border-1 border-white"></hr>
      <div className="select_box text-center">
        <p className="mt-4 mb-1 text-sm text-shadow-blue">Comparar con:</p>
        <select
          onChange={handleChange}
          value={selectedChart}
          id="selectedChart"
          className='text-sm border-shadow-blue rounded-full cursor-pointer'
        >
          <option value="preconteo">Avance de preconteo</option>
          <option value="pres1era2022">Primera vuelta 2022</option>
          <option value="pres2da2018">Segunda vuelta 2018</option>
          <option value="pres2da2014">Segunda vuelta 2014</option>
          <option value="plebiscito2016">Plebiscito 2016</option>
        </select>
        {/* <p>{state.currentRegion}</p> */}
        {
          selectedChart === 'preconteo' && <PrecountLineChart reports={reports} colors={colors} />
        }
        {
          ['pres1era2022', 'pres2da2018', 'pres2da2014'].includes(selectedChart) && <ComparisonChart plotData={plotData} dataKey="candidato" />
        }
        {
          selectedChart === 'plebiscito2016' && <ComparisonChart plotData={plotData} dataKey="resultado" />
        }
      </div>
    </div>
  </div>)
}

DetailsPanel.propTypes = {
  data: PropTypes.object,
  current: PropTypes.string
}

CustomBarChartTick.propTypes = {
  value: PropTypes.number,
  offset: PropTypes.number,
  viewBox: PropTypes.object
}

Tooltip.propTypes = {
  payload: PropTypes.object,
  Votos: PropTypes.number
}

export default DetailsPanel
