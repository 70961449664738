import { BarChart, ReferenceLine, ResponsiveContainer, XAxis, YAxis, Tooltip, Bar, LabelList } from 'recharts'
import PropTypes from 'prop-types'
import { addSuffix } from '../utils'

function CustomBarChartTick ({ value, offset, viewBox }) {
  return <text x={viewBox.width / 2 + viewBox.x} y={viewBox.y + viewBox.height / 2 + 10} offset={offset} width={viewBox.width} height={viewBox.height} fill="#ffffff" className="font-bold text-lg sm:text-2xl lg:text-4xl mb-8" textAnchor="middle">
    {value.toFixed(2)}%
  </text>
}

function ComparisonChart ({ plotData, dataKey }) {
  return (
    <ResponsiveContainer height={250} className="text-xs mt-4">
      <BarChart data={plotData.slice(0, 2)} margin={{ top: 0, right: 0, bottom: 20, left: 0 }}>
        <ReferenceLine y={50} stroke="#324376" strokeDasharray="3 3" />
        <XAxis dataKey={dataKey} tickMargin={10} tickLine={false} axisLine={false} />
        <YAxis domain={[0, 70]} ticks={[50]} tickLine={false} tickCount={3} axisLine={false} tickFormatter={(value) => addSuffix('%', value)} />
        <Tooltip
          separator={''}
          cursor={false}
          itemStyle={{ color: '#324376' }}
          wrapperStyle={{ color: '#324376' }}
          labelStyle={{ fontWeight: '300', textTransform: 'capitalize', fontSize: '12px' }}
          contentStyle={{ color: '#324376', fontWeight: 'bold', fontSize: '22px', borderColor: '#63A9DE', borderRadius: '15px', backgroundColor: 'rgba(255,255,255,0.95)', padding: '1.5rem' }}
          offset={5}
          formatter={(value, name, p) => {
            return (p.payload.votos ? [p.payload.votos.toLocaleString('en-US') + ' votos', ''] : [p.payload.porcentaje.toFixed(2) + '%', ''])
          } }
        />
        <Bar dataKey="porcentaje" fill='#63A9DE'>
          <LabelList dataKey="porcentaje" position="top" content={<CustomBarChartTick />} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  )
}

ComparisonChart.propTypes = {
  plotData: PropTypes.arrayOf(PropTypes.object),
  dataKey: PropTypes.string.isRequired
}

CustomBarChartTick.propTypes = {
  value: PropTypes.number,
  offset: PropTypes.number,
  viewBox: PropTypes.object
}

export default ComparisonChart
