import { useContext } from 'react'
import PropTypes from 'prop-types'
import { AppContext } from '../App'

function DetailsPanelHeader ({ current, info }) {
  const { state } = useContext(AppContext)
  const municipality = state.selectedMunicipality.toLowerCase()
  return (
    <div className="pt-2 flex flex-col gap-4 text-sm lg:flex-row lg:justify-between lg:px-6 lg:pt-0">
      <div className='max-w-xs w-full'>
        <p>
          Boletín #<span className="font-bold">{info['Boletín']}</span>
        </p>
        <div className='flex items-center w-full lg:self-center'>
          <img src="images/location.svg" className="w-8 h-auto mt-1 mr-1 -ml-1"/>
          <h3 className='mt-1 text-2xl font-bold leading-6 capitalize'>
            { municipality.charAt(0).toUpperCase() + municipality.substr(1) || current.charAt(0).toUpperCase() + current.substr(1)}
          </h3>
        </div>
      </div>
      <div className='pt-2 flex space-x-4 lg:pt-0'>
        <div className='flex-shrink-0'>
          <p>Mesas informadas</p>
          <h3 className='mt-1 text-2xl text-baby-blue font-medium capitalize'>{info.Porc_Mesas_Informadas?.toFixed(2)}%</h3>
        </div>
        <div className='flex-shrink-0'>
          <p>Abstención</p>
          <h3 className='mt-1 text-2xl text-baby-blue font-medium capitalize'>{(100 - info.Porc_Sufragantes).toFixed(2)}%</h3>
        </div>
      </div>
    </div>
  )
}

DetailsPanelHeader.propTypes = {
  current: PropTypes.string,
  info: PropTypes.object
}

export default DetailsPanelHeader
