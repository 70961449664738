import { useContext } from 'react'
import slugify from 'slugify'
import { AppContext } from '../App'
import { initialState } from '../reducer/app'

function Breadcrumb () {
  const { state, dispatch } = useContext(AppContext)

  const handleReset = () => {
    dispatch({ type: 'SET_CURRENT_REGION', payload: initialState.currentRegion })
    dispatch({ type: 'SET_CENTER', payload: initialState.center })
    dispatch({ type: 'SET_ZOOM', payload: initialState.zoom })
    dispatch({ type: 'SET_CURRENT_REGION_LABEL', payload: initialState.currentRegionLabel })
    dispatch({ type: 'SET_MUNICIPALITY', payload: '' })
    dispatch({ type: 'SET_INPUT_SEARCH', payload: '' })
    dispatch({ type: 'SET_MUNICIPALITIES', payload: [] })
  }

  const handleResetDepartment = () => {
    dispatch({ type: 'SET_MUNICIPALITY', payload: '' })
    dispatch({ type: 'SET_INPUT_SEARCH', payload: '' })
    dispatch({ type: 'SET_CURRENT_REGION', payload: state.currentRegion })
    dispatch({ type: 'SET_CURRENT_REGION_INFO', payload: state.memo[state.currentRegionLabel] })
  }

  const handleChange = (e) => {
    const { value } = e.target
    dispatch({ type: 'SET_MUNICIPALITY', payload: value })
    if (!value) {
      handleResetDepartment()
    } else {
      const info = state.municipalities[value]
      dispatch({ type: 'SET_CURRENT_REGION_INFO', payload: info })
    }
  }

  if (state.currentRegion === 'colombia') return <p className='py-1 text-sm text-space-cadet'>Colombia</p>

  return (
    <div className='text-sm text-space-cadet flex space-x-2 items-center justify-start min-w-0'>
      <button onClick={handleReset} className="text-sm cursor-pointer underline">Colombia</button>
      <p>&gt;</p>
      <button
        onClick={handleResetDepartment}
        className="text-sm cursor-pointer underline max-w-md truncate"
        title={state.currentRegionLabel}
      >
        {state.currentRegionLabel}
      </button>
      <p>&gt;</p>
      <select
        value={state.selectedMunicipality}
        onChange={handleChange}
        className="truncate w-28 cursor-pointer text-sm pl-2 px-4 py-1 border border-shadow-blue rounded-full min-w-0"
      >
        <option value="">Todos</option>
        {Object.keys(state.municipalities).filter(m => m !== 'NO APLICA').map(m => (
          <option key={slugify(m, { lower: true })} value={m}>
            {m}
          </option>
        ))}
      </select>
    </div>
  )
}

export default Breadcrumb
