import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import PropTypes from 'prop-types'
import { addPrefix, addSuffix } from '../utils'

function PrecountLineChart ({ reports, colors }) {
  return (
    <>
      <ResponsiveContainer width="100%" height={250} className="text-xs mt-4">
        <LineChart data={reports} margin={{ top: 0, right: 0, bottom: 50, left: 0 }}>
          <CartesianGrid vertical={false} stroke="#eee" />
          <XAxis dataKey="Boletín" padding={{ left: 20, right: 20 }} tickMargin={15} tickLine={false} angle={45} axisLine={false} tickFormatter={(value) => addPrefix('#', value)} />
          <YAxis domain={[0, 100]} tickLine={false} tickCount={3} style={{ fontSize: '12px' }} tickFormatter={(value) => addSuffix('%', value)} />
          <Tooltip
            separator={': '}
            cursor={false}
            itemStyle={{ fontSize: '18px', fontWeight: 'bold' }}
            wrapperStyle={{ color: '#324376' }}
            labelStyle={{ textTransform: 'capitalize', fontSize: '12px', marginBottom: '10px', fontWeight: 'medium', color: '#63A9DE' }}
            contentStyle={{ fontSize: '12px', borderColor: '#63A9DE', borderRadius: '15px', backgroundColor: 'rgba(255,255,255,0.95)', padding: '1.5rem' }}
            offset={5}
            labelFormatter={(value) => 'Boletín #' + value}
            formatter={(value, name, p) => {
              return [value + '%', p.dataKey.toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))]
            }}
          />
          <Line type="monotone" dataKey="GUSTAVO PETRO" stroke={colors[0]} fill={colors[0]} />
          <Line type="monotone" dataKey="RODOLFO HERNÁNDEZ" stroke={colors[1]} fill={colors[1]} />
        </LineChart>
      </ResponsiveContainer>
    </>
  )
}

PrecountLineChart.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
  reports: PropTypes.arrayOf(PropTypes.object)
}

export default PrecountLineChart
