export const initialState = {
  tooltip: '',
  currentRegion: 'colombia',
  currentRegionInfo: null,
  zoom: 25,
  center: [-72.083, 4.317],
  currentRegionLabel: 'colombia',
  markers: [],
  municipalities: [],
  selectedMunicipality: '',
  memo: {},
  inputSearch: ''
}

export default function appReducer (state, action) {
  const { type, payload } = action
  switch (type) {
    case 'SET_TOOLTIP':
      return { ...state, tooltip: payload }
    case 'SET_CENTER':
      return { ...state, center: payload }
    case 'SET_ZOOM':
      return { ...state, zoom: payload }
    case 'SET_CURRENT_REGION':
      return { ...state, currentRegion: payload }
    case 'SET_CURRENT_REGION_LABEL':
      return { ...state, currentRegionLabel: payload }
    case 'SET_CURRENT_REGION_INFO':
      return { ...state, currentRegionInfo: payload }
    case 'SET_MARKERS':
      return { ...state, markers: payload }
    case 'SET_MUNICIPALITIES':
      return { ...state, municipalities: payload }
    case 'SET_MUNICIPALITY':
      return { ...state, selectedMunicipality: payload }
    case 'SET_MEMO':
      return { ...state, memo: { ...state.memo, [payload.key]: payload.value } }
    case 'SET_INPUT_SEARCH':
      return { ...state, inputSearch: payload }
  }
}
