import useSWR from 'swr'

function useData (name) {
  const { data, error } = useSWR(`/data/${name}.json`, (...args) => fetch(...args).then(res => res.json()), {
    refreshInterval: 60000
  })

  return {
    region: data,
    error,
    loading: !error & !data
  }
}

export default useData
