import slugify from 'slugify'

export function removeAccent (str) {
  return str
    .normalize('NFD')
    .replace(/(?:[\u0300-\u0302]|[\u0304-\u036f])/g, '')
    .normalize('NFC')
}

export function toSlug (str) {
  return slugify(str.replace(/\./g, ' ').trim(), { lower: true, strict: true })
}

export function addSuffix (suffix, value) {
  return value + suffix
}

export function addPrefix (prefix, value) {
  return prefix + value
}
