import { useContext, useEffect, useState } from 'react'
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from 'react-simple-maps'
import ReactTooltip from 'react-tooltip'
import { scaleLinear, scaleSymlog } from 'd3-scale'
import { extent } from 'd3-array'
import { AppContext } from '../App'
import labels from '../data/labels.json'
import dptos from '../data/departamentos.json'
import { toSlug } from '../utils'

function Map () {
  const { state, dispatch } = useContext(AppContext)
  const [radiusScale, setRadiusScale] = useState(() => () => { })
  const [showMarkers, setShowMarkers] = useState(false)

  useEffect(() => {
    if (!state.markers.length) return
    setRadiusScale(() => scaleLinear()
      .domain(extent(state.markers, d => d.Potencial_Sufragantes))
      .range([0.05, 0.1])
    )
    setShowMarkers(true)
  }, [state.markers])

  const colorScale = scaleSymlog().domain([-40, 0, 40]).range(['#FA8030', '#F1F1F1', '#B47CF9'])

  const handleMouseEnter = (geography) => {
    const { NOMBRE_DPT, NOMBRE_MPI } = geography.properties
    dispatch({ type: 'SET_TOOLTIP', payload: NOMBRE_MPI || labels[NOMBRE_DPT] })
  }

  const handleMouseLeave = () => {
    dispatch({ type: 'SET_TOOLTIP', payload: '' })
  }

  const handleClick = (geography, projection, path) => {
    const { NOMBRE_DPT } = geography.properties
    const slug = toSlug(NOMBRE_DPT)
    const centroid = projection.invert(path.centroid(geography))
    dispatch({ type: 'SET_CURRENT_REGION', payload: slug }) // This will fetch JSON data
    dispatch({ type: 'SET_CURRENT_REGION_LABEL', payload: labels[NOMBRE_DPT] })
    dispatch({ type: 'SET_CENTER', payload: centroid })
    dispatch({ type: 'SET_ZOOM', payload: 75 })
    dispatch({ type: 'SET_INPUT_SEARCH', payload: '' })
    if (slug === 'bogota-d-c') {
      dispatch({ type: 'SET_MUNICIPALITY', payload: 'BOGOTA D.C.' })
    } else {
      dispatch({ type: 'SET_MUNICIPALITY', payload: '' })
    }
  }

  return (
    <>
      <ComposableMap
        projection="geoAzimuthalEquidistant"
        projectionConfig={{
          rotate: [75, 20, 0],
          scale: 45
        }}
        width={500}
        height={350}
        style={{
          width: '100%',
          height: '100%'
        }}
        data-tip=""
      >
        <ZoomableGroup
          zoom={state.zoom}
          minZoom={20}
          maxZoom={300}
          center={state.center}
        >
          <Geographies
            geography={dptos}
          >
            {({ geographies, projection, path }) => geographies.map((geography) => (
              <Geography
                key={geography.rsmKey}
                geography={geography}
                fill={toSlug(geography.properties.NOMBRE_DPT) === state.currentRegion ? '#63A9DE' : '#D7D7D7'}
                stroke="#eee"
                strokeWidth={0.01}
                onMouseEnter={() => handleMouseEnter(geography)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleClick(geography, projection, path)}
                style={{
                  default: {
                    outline: 'none'
                  },
                  hover: {
                    fill: toSlug(geography.properties.NOMBRE_DPT) === state.currentRegion ? '#63A9DE' : '#c0dcf1',
                    outline: 'none',
                    cursor: 'pointer'
                  },
                  pressed: {
                    fill: '#63A9DE',
                    outline: 'none'
                  }
                }}
              />
            ))}
          </Geographies>
          {showMarkers && state.markers.map((marker) => (
            <Marker key={marker.id} coordinates={[marker.lon, marker.lat]}>
              <circle
                r={Math.sqrt(radiusScale(marker.Potencial_Sufragantes)) / 1}
                stroke="#324376"
                strokeWidth={0.01}
                fill="none"
              />
              <circle
                r={Math.sqrt(scaleLinear()
                  .domain([0, marker.Potencial_Sufragantes])
                  .range([0, radiusScale(marker.Potencial_Sufragantes)])(marker['Votos_RODOLFO HERNÁNDEZ'] + marker['Votos_GUSTAVO PETRO']))
                }
                fill={colorScale(marker['Porcentaje_GUSTAVO PETRO'] - marker['Porcentaje_RODOLFO HERNÁNDEZ'])}
              />
            </Marker>
          ))}
        </ZoomableGroup>
      </ComposableMap>
      <ReactTooltip className="background-color: red">{state.tooltip}</ReactTooltip>
    </>
  )
}

export default Map
