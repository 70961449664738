import PropTypes from 'prop-types'
import classNames from 'classnames'

function Panel ({ children, ...restProps }) {
  return <div className={classNames('bg-white shadow h-full max-h-full overflow-auto p-4 border-t-2 border-baby-blue flex flex-col', restProps.className || '')} style={{ minHeight: '600px' }}>
    {children}
  </div>
}

Panel.Title = function PanelTitle ({ text }) {
  return <h2 className='uppercase tracking-wide text-sm font-bold text-baby-blue'>{text}</h2>
}

Panel.Body = function PanelBody ({ children }) {
  return <div className='mt-4 flex-grow'>{children}</div>
}

Panel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ])
}

Panel.Title.propTypes = {
  text: PropTypes.string.isRequired
}

Panel.Body.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ])
}

export default Panel
