import { createContext, useEffect, useMemo, useReducer } from 'react'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import DetailsPanel from './components/DetailsPanel'
import Panel from './components/Panel'
import useData from './hooks/useData'
import Map from './components/Map'
import appReducer, { initialState } from './reducer/app'
import Breadcrumb from './components/Breadcrumb'
import searchItems from './data/search.json'
import { removeAccent } from './utils'

export const AppContext = createContext(null)

function App () {
  const [state, dispatch] = useReducer(appReducer, initialState)
  const { region, loading, error } = useData(state.currentRegion)

  useEffect(() => {
    if (!region) return

    dispatch({ type: 'SET_MEMO', payload: { key: state.currentRegionLabel, value: region } })
    dispatch({ type: 'SET_CURRENT_REGION_INFO', payload: region })

    if (region.resultados_departamental) {
      dispatch({ type: 'SET_MARKERS', payload: region.resultados_departamental })
    } else if (region.resultados_municipios) {
      dispatch({ type: 'SET_MUNICIPALITIES', payload: region.resultados_municipios })
      if (state.selectedMunicipality) {
        dispatch({ type: 'SET_CURRENT_REGION_INFO', payload: region.resultados_municipios[state.selectedMunicipality] })
      }
    }
  }, [region])

  const value = useMemo(() => ({
    state,
    dispatch
  }), [state, dispatch])

  const handleOnSelect = (item) => {
    const choice = removeAccent(item.municipio).toUpperCase()
    dispatch({ type: 'SET_MUNICIPALITY', payload: choice })
    dispatch({ type: 'SET_CURRENT_REGION', payload: item.id_departamento })
    dispatch({ type: 'SET_CURRENT_REGION_LABEL', payload: item.departamento })
    dispatch({ type: 'SET_ZOOM', payload: initialState.zoom })
    dispatch({ type: 'SET_CENTER', payload: initialState.center })
    if (state.currentRegion === item.id_departamento) {
      const info = state.municipalities[choice]
      dispatch({ type: 'SET_CURRENT_REGION_INFO', payload: info })
    }
  }

  const handleClear = () => {
    dispatch({ type: 'SET_CURRENT_REGION', payload: initialState.currentRegion })
    dispatch({ type: 'SET_CENTER', payload: initialState.center })
    dispatch({ type: 'SET_ZOOM', payload: initialState.zoom })
    dispatch({ type: 'SET_CURRENT_REGION_LABEL', payload: initialState.currentRegionLabel })
    dispatch({ type: 'SET_MUNICIPALITY', payload: '' })
    dispatch({ type: 'SET_MUNICIPALITIES', payload: [] })
  }

  const formatResult = (item) => {
    return (
      <>
        <span className='font-ibm-plex-sans text-space-cadet'>{item.municipio}</span>
        <span className='block text-xs text-opacity-70 truncate'>{item.departamento}</span>
      </>
    )
  }

  return (
    <AppContext.Provider value={value}>
      <div className="h-screen font-ibm-plex-sans text-space-cadet">
        <div className="p-6 flex flex-col gap-5 bg-slate-50 h-full md:flex-row">
          <Panel className="w-full md:w-1/2">
            <Panel.Title text='Visualización' />
            <Panel.Body>
              <div className="flex flex-col h-full justify-start">
                <div className="flex flex-col gap-4 lg:flex-row lg:justify-between">
                  <div className='flex items-center w-full lg:w-1/2 lg:self-center'>
                    <img src="images/location.svg" className="w-5 h-auto mr-2"/>
                    <Breadcrumb />
                  </div>
                  <div className='w-full lg:w-1/2'>
                    <ReactSearchAutocomplete
                      inputSearchString={state.inputSearch}
                      className='text-sm py-1 px-4 w-full border border-gray/50 rounded-full placeholder:text-shadow-blue'
                      styling={{
                        border: '1px solid rgb(202 202 202 / 50%)',
                        fontFamily: 'IBM Plex Sans',
                        placeholderColor: 'rgb(202, 202, 202)',
                        iconColor: '#63A9DE'
                      }}
                      placeholder="Buscar"
                      items={searchItems}
                      fuseOptions={{ threshold: 0.4, keys: ['municipio', 'departamento'] }}
                      resultStringKeyName="municipio"
                      showNoResultsText="Sin resultados"
                      formatResult={formatResult}
                      onSelect={handleOnSelect}
                      onClear={handleClear}
                      onSearch={(str) => dispatch({ type: 'SET_INPUT_SEARCH', payload: str })}
                    />
                  </div>
                </div>
                <div className="grow mt-4"><Map /></div>
                <div className="w-full">
                <div className="pb-2 bg-white w-full flex justify-between items-end">
                    <div className="rounded-tr-xl pl-4 pr-6 pt-4">
                      <img src="images/legend.svg" className="w-52 h-auto"/>
                    </div>
                    <a className="px-4" href="https://www.datasketch.co/?utm_source=canal-capital&utm_medium=elecciones-2022-app&utm_campaign=canal-capital">
                      <img src="images/powered_by.svg" className="w-36 h-auto"/>
                    </a>
                </div>
                </div>
              </div>
            </Panel.Body>
          </Panel>
          <Panel className="w-full md:w-1/2">
            <Panel.Title text='Detalle' />
            <Panel.Body>
              {loading ? <p>Cargando</p> : null}
              {error && <div className='text-center space-y-2'>
                <p className='text-center text-2xl'>Algo salió mal 😵‍💫</p>
                <button className='bg-slate-100 px-4 py-2' onClick={() => document.location.reload()}>Recargar</button>
              </div>}
              {state.currentRegionInfo && !error && <DetailsPanel current={state.currentRegionLabel.toLowerCase()} data={state.currentRegionInfo} />}
            </Panel.Body>
          </Panel>
        </div>
      </div>
    </AppContext.Provider>
  )
}

export default App
